import { computed } from 'vue'
import { useStore } from 'vuex'
import type { Profile } from '@/models/User.interface'
import type { Organization } from "@/models";

export default () => {
  const store = useStore()

  const organization = computed<Organization>(() => store.state.organizations.organization)
  // userStore and userStoreOrganizations are name like this to avoid confusion with user from useHeaderPublic
  const userStore = computed<Profile>(() => store.getters['user/getMe']())
  const userStoreOrganizations = computed(() => store.getters['user/getUserOrganizations']())

  return {
    userStore,
    userStoreOrganizations,
    organization
  }
}
